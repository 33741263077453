<template>
  <div class="about" v-if="contant">
    <header>
      <van-nav-bar
        :title="type == 0 ? $t('about.title') :  $t('about.guanyutixian')"
        :style="bgColor"
      >
        <template #left>
          <van-icon name="arrow-left" size="20" color="#fff"  @click="JumpGo"/>
        </template>
      </van-nav-bar>
    </header>
    <div class="main">
      <div class="title">
        {{ type == 0 ?$t('about.title') :  $t('about.guanyutixian') }}
      </div>
      <div class="date">2021-09-04 18:52:46</div>
      <div class="content" v-html="contant"></div>
    </div>
        <tabbar />
  </div>
</template>

<script>
// @ is an alias to /src
import Tabbar from "@/components/tabbar/tabbar.vue";
export default {
  name: "About",
  components: {Tabbar},
  data() {
    return {
      type: "",
      contant: "",
      bgColor:''
    };
  },
  methods:{
    JumpGo(){
           this.$router.go(-1);
    },
  },
  created() {
     this.bgColor = localStorage.getItem("bgColor");
    this.$toast.loading({
    message: this.$t('Conectando'),
      forbidClick: true,
      duration: 300,
    });
    this.type = this.$route.query.type;
    this.contant = JSON.parse(this.$route.query.contant);
  },
};
</script>
<style lang="less" scoped>
.about {
  background: #f1f1f1;
  font-size: 0.37rem;
  height: 100vh;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: left;
}
.main {
  padding: 0 0.4rem 0.4rem;
  box-sizing: border-box;
}
.title {
  font-size: 0.53rem;
  font-weight: 700;
  margin-top: 0.4rem;
}
.date {
  color: #999;
  font-size: 0.35rem;
  margin: 0.4rem 0;
}
.content {
}
</style>